export default {
  title: 'Settings',
  organization: 'Organization',
  isBeingCreated: {
    title: 'Workspace',
    description: 'Your organization is being set up, the process will take around 30 seconds, please wait.'
  },
  sfConnectDialog: {
    title: 'Welcome to CARTO',
    headlineOne: 'CARTO is now connected to Snowflake!',
    paragraphOne:
      'Go ahead and unlock the power of spatial analysis with CARTO, using your own Snowflake data. From now on, you can always go back to CARTO through Snowflake Partner Connect.',
    headlineTwo: 'Here are some resources to get you started:',
    docs: {
      first: 'Documentation: CARTO Analytics Toolbox for Snowflake.',
      second: 'Demo: Locating optimal sites for 15 minute grocery delivery.',
      third: 'Webinar: How to use CARTO with Snowflake.'
    }
  },
  sections: {
    general: 'General',
    usageWithoutActivity: 'Usage & Quotas',
    usage: 'Quotas & Activity',
    subscriptions: 'Subscription',
    apps: 'Registered apps',
    users: 'Users & Groups',
    appearance: 'Customizations',
    analytics: 'Analytics Toolbox',
    advanced: 'Advanced',
    assetManagement: 'Asset management',
    dataObservatory: 'Data Observatory'
  },
  cards: {
    general: {
      title: 'Overview',
      accountName: 'Organization name',
      accountId: 'Organization ID',
      version: 'Version',
      cartoCloudVersion: 'CARTO Cloud',
      cartoSelfHostedVersion: 'Self-hosted',
      rename: 'Edit name',
      renameModal: {
        title: 'Rename organization',
        invalid: 'Invalid name',
        cancel: 'Cancel',
        submit: 'Rename'
      },
      successMessage: 'The organization was renamed correctly',
      billing: {
        title: 'Billing'
      }
    },
    stats: {
      chart: {
        mapLoads: { label: 'Map loads', description: '' },
        apps: {
          label: 'Applications',
          description:
            'Maximum number of applications you can create in the Developers section, for authentication in the CARTO APIs.'
        },
        tokens: {
          label: 'API Access Tokens',
          description:
            'Maximum number of API Access Tokens created using Workspace or the Tokens API. Tokens are used by public maps and public applications.'
        },
        usage: {
          label: 'Usage quota',
          descriptionWithoutActivity:
            'Usage Quota units are consumed every time a user interacts with the CARTO Platform and APIs, excluding metadata. The Usage Quota is annual and it will reset on {date}.',
          description:
            'Usage Quota units are consumed every time a user interacts with the CARTO Platform and APIs, excluding metadata. The Usage Quota is annual and it will reset on {date}. You can find a breakdown of the usage quota by day, user or API in the <a>Activity data</a>.'
        },
        usageOldPricing: {
          label: 'Usage quota',
          description:
            'Usage Quota units are consumed every time a user interacts with the CARTO Platform and APIs, excluding metadata. This quota is annual and does not apply. It is purely informative for your organization.'
        },
        usagePerMonth: {
          label: 'Average monthly usage',
          alertText: {
            success:
              'At the current usage level, your organization should have enough quota for the remainder of the subscription.',
            warning: 'At the current usage level, your organization will run out of quota by {date}.',
            warningExceeded: 'Your organization is already over quota.',
            warningButton: 'Learn more'
          }
        },
        maps: { label: 'Total maps', description: 'Total number of maps in your CARTO organization' },
        mapsPublic: {
          label: 'Public maps',
          description: 'Total number of maps shared in Public mode in your CARTO organization'
        },
        connections: {
          label: 'Connections',
          description: 'Total number of connections created in your CARTO organization'
        },
        users: { label: 'Users', description: '' },
        editorUsers: {
          label: 'Editor users',
          description: 'Users that can create and edit maps, connections, applications...'
        },
        viewerUsers: {
          label: 'Viewer users',
          description: 'Users that can view maps and applications created by Editors'
        },
        geocode: {
          label: 'Geocoding / month',
          description: 'LDS credits are reset on the first day of the month'
        },
        lds: {
          label: 'Geocoding and Isolines/year',
          description: 'LDS credits are annual and they will reset on {date}.'
        },
        isolines: {
          label: 'Isolines / month',
          description: 'LDS credits are reset on the first day of the month'
        },
        indicator: '{current} of {max}',
        errorMessage:
          "There was an error loading the organization's stats. Please try again later and contact us if the error persists."
      }
    },
    usage: {
      quotas: {
        title: 'Quotas',
        learnMore: {
          title: 'Learn more',
          content: 'Find more information about these quotas and other relevant resources.',
          button: 'Check our documentation'
        }
      },
      activityData: {
        title: 'Activity Data',
        content: {
          title: 'Export CARTO activity data',
          description:
            'Activity data tracks all user actions and API usage in your CARTO organization. Administrators can export it for custom analysis, CARTO activity reporting, quota monitoring, or as security audit logs.',
          exportButton: 'Export CARTO activity data',
          apiAccess: {
            title: 'Access via API',
            description:
              'You can access CARTO activity data programmatically via API, which allows for seamless integration of this data into your existing dashboards and tools.',
            learnMore: 'Learn more',
            tooltip: 'Only available for Medium license plans or higher'
          },
          upgrade: {
            title: 'Upgrade your plan',
            message:
              "Exporting activity data from your CARTO organization requires a larger Enterprise plan. Let us know if you're interested and we'll guide you through the process."
          }
        },
        form: {
          title: 'Export CARTO activity data',
          description:
            'Export data containing all user actions and API usage in your CARTO organization, for the date range selected below.',
          fields: {
            from: 'From',
            to: 'To',
            format: 'File format',
            formats: {
              csv: 'CSV',
              parquet: 'Parquet'
            }
          },
          errors: {
            endBeforeStart: 'End date must be after start date',
            startBeforeAccountCreation: "There's no data before your CARTO organization was created",
            rangeExceeded: 'Each export can contain up to 60 days of data',
            futureDate: 'Date cannot be today or a future date'
          },
          info: 'Each export can contain up to 60 days of data. Dates are calculated using UTC',
          button: 'Export data'
        }
      },
      title: 'Subscription',
      quotaTitle: 'Quota',
      detailsTitle: 'Details',
      usersTitle: 'Users',
      featuresTitle: 'Features',
      usageTitle: 'Usage',
      cartoForDevelopers: 'Developers',
      locationDataServices: 'Location Data Services (LDS)',
      items: {
        domain: 'Domain',
        expires: 'Expires',
        billingInformation: 'Billing information',
        manageBillingInformation: 'Manage billing',
        trial: 'Trial',
        plan: 'Current plan',
        enterpriseSSO: 'Current status',
        mfa: 'Multi Factor Authentication',
        region: 'Region'
      }
    },
    users: {
      title: 'Users',
      inviteUsersDialog: {
        title: 'Invite user',
        content: 'Invite new members by email to join your organization',
        inputPlaceholder: 'name@email.com',
        inputLabel: 'Email address',
        invite: 'Invite',
        successMessage: 'Invitation correctly sent',
        role: 'User role'
      },
      invitations: {
        title: 'Invitations',
        notFound: 'No pending invitations',
        searchPlaceholder: 'Search invitations',
        status: {
          sent: 'Invitation sent',
          expired: 'Invitation expired'
        },
        expiredTooltip:
          'Invitations are valid for 7 days. Re-send this invitation using the three-dot menu to make it valid again',
        actions: {
          resend: 'Resend invitation',
          withdraw: 'Withdraw it'
        },
        sendDialog: {
          title: 'Invite users',
          content: 'Send invitations via email so these users can join your organization.',
          submit: 'Invite',
          success: 'Invitations correctly sent',
          error:
            "Invitations could not be sent (they may be already registered). Go to Invitations to check the current invitations' status and contact us if you need help",
          someEmailsError:
            "This invitation could not be sent. Check that the email is valid, that it's not already invited/registered, or other potential issues",
          inputPlaceholder: 'user1@email.com, user2@gmail.com',
          inputLabel: 'Email addresses',
          inputHint: 'You can invite multiple email addresses, separated by comma.',
          role: 'Role',
          alreadyInvitedError: 'Some emails have been already invited, please check above',
          alreadyJoinedError: 'Some emails have been already registered, please check above',
          multipleErrors:
            "Some of the addresses can't be invited because they are invalid, already registered or invited, or other reasons. Please review and try again."
        },
        resendDialog: {
          title: 'Resend invitation',
          content: 'Are you sure you want to resend {email} an invitation to join your organization?',
          submit: 'Yes, resend',
          success: 'Invitation correctly resent',
          error: 'The invitation could not be resent. Please try again or contact support if the issue persists.'
        },
        withdrawDialog: {
          title: 'Withdraw invitation',
          content_1: 'Are you sure you want to withdraw the invitation sent to {email}?',
          content_2: "They won't be able to join your organization using the previous invite.",
          submit: 'Withdraw invitation',
          success: 'Invitation correctly withdrawn',
          error: "The invitation couldn't be withdrawn. Please try again or contact support if the issue persists."
        }
      },
      requests: {
        title: 'Requests',
        notFound: 'No pending requests',
        searchPlaceholder: 'Search requests',
        status: {
          approved: 'Request approved',
          pending: 'Request pending',
          expired: 'Request expired'
        },
        expiredTooltip:
          'This request has expired. You can send an invitation to this user, or they can submit another request to join at any time',
        actions: {
          approve: 'Approve request',
          decline: 'Decline it'
        },
        approveDialog: {
          title: 'Approve request',
          content: 'Are you sure you want to approve {email} request to join your organization?',
          submit: 'Yes, approve',
          success: 'Request correctly approved',
          error: 'The request could not be approved. Please try again or contact support if the issue persists.'
        },
        declineDialog: {
          title: 'Decline request',
          content_1: 'Are you sure you want to decline the request sent by {email}?',
          content_2: "They won't be able to join your organization using the previous request.",
          submit: 'Decline request',
          success: 'Request correctly declined',
          error: "The request couldn't be declined. Please try again or contact support if the issue persists."
        }
      },
      sso: {
        tab: 'SSO',
        title: 'Single Sign-On (SSO)',
        message:
          'Users can authenticate to this CARTO organization using a Single Sign-On (SSO) integration, that is, leveraging their own company identity. This provides several security benefits and streamlines the user onboarding process.',
        url: 'URL SSO',
        urlCopied: 'URL copied to clipboard',
        urlMessage:
          'This URL grants access to the organization using your company Single Sign-On.<br></br>You can send this URL to your teammates if you want them to join this CARTO organization.',
        roleManagement: 'Role management',
        info: {
          lookingEnable: {
            title: 'Looking to enable Single Sign-On?',
            message:
              "Let us know if you want to start using SSO to authenticate your users and we'll guide you through the process."
          },
          upgrade: {
            title: 'Upgrade your plan',
            message:
              "Single Sign-On requires a larger Enterprise plan. Let us know if you want to start using SSO to authenticate your users and we'll guide you through the process."
          }
        },
        enforcedLoginMethod: {
          label: 'Enforce Single Sign-On as the only authentication method',
          helperText:
            'When enabled, users in your organization will only be able to login using Single Sign-On. Other authentication mechanisms will be disabled. <a>More info</a>',
          disabledTooltipText:
            'The organization must have at least one Admin using SSO authentication. <a>More info</a>',
          success: 'Successfully updated "Enforce Single Sign-On" settting.',
          error: 'Failed to update "Enforce Single Sign-On" settting.'
        },
        jitProvisioning: {
          label: 'Enable Just-in-Time provisioning',
          helperText:
            'Allows users accessing with SSO to register in CARTO without going through the Signup form. <a>More info</a>',
          success: 'Successfully updated Just-in-Time settting of an account.',
          error: 'Failed to update Just-in-Time settting of an account.'
        },
        groupsRoleManagement: {
          superadminInYourOrgMessage:
            'Group-role Mapping is disabled since there is a Superadmin user in your organization. If you wish to enable this, please contact Support to get the process started.',
          label: 'Enable role management by groups',
          noGroupsHelperText:
            'You need to have at least one group before enabling role management by groups. Please create one in the Groups tab.',
          helperText:
            'You will be mapping your SSO groups to roles, and users will be automatically granted the highest-privileged role from the groups they belong to.'
        }
      },
      groups: {
        info: {
          lookingEnable: {
            title: 'Looking to enable user groups?',
            message:
              "Use CARTO at scale with fully synchronized groups coming from your SSO integration, that your users will leverage for granular sharing and collaboration. Let us know if you want to enable Groups and we'll guide you through the process."
          },
          upgrade: {
            title: 'Looking to enable user groups?',
            message:
              "Use CARTO at scale with fully synchronized groups coming from your SSO integration, that your users will leverage for granular sharing and collaboration. In order to use Groups you will need to upgrade your plan. Let us know and we'll guide you through the process."
          }
        }
      },
      dontRequireJoinApproval: 'Let anyone with a @{emailDomain} email join this organization.',
      table: {
        headCell: {
          users: 'User',
          role: 'Role',
          authMethod: 'Authentication method',
          status: 'Status',
          added: 'Added',
          invited: 'Invited'
        }
      },
      roleFilters: {
        allUsers: 'All users',
        allGroups: 'All groups',
        allRoles: 'All roles',
        admins: 'Admins',
        builders: 'Editors',
        viewers: 'Viewers',
        superadmins: 'Superadmins'
      },
      wouldExceedQuotaModal: {
        quotaTitle: '{capitalizedRole} users',
        alertMessage:
          'You can’t add any more {role} users with your current plan. Free up a seat by modifying or removing other users, or request to be contacted for an upgrade.',
        invite: {
          title: "Couldn't send the invite",
          message: 'Check the exceeded quotas below before inviting <strong>{email}</strong> as a {role} user.'
        },
        assignRole: {
          title: "Couldn't assign user role",
          message: 'Check the exceeded quotas below before assigning <strong>{email}</strong> as a {role} user.'
        },
        moreInfo: {
          title: "Can't add new {role} users",
          message: 'Check the exceeded quotas below before inviting a user or changing their roles.'
        }
      },
      actions: {
        delete: 'Delete',
        makeEditor: 'Make Editor',
        makeAdmin: 'Make Admin',
        makeViewer: 'Make Viewer',
        makeSuperadmin: 'Make Superadmin'
      },
      deleteUsersDialog: {
        content: 'Are you sure you want to delete {email} from the organization? This action cannot be undone.',
        title: 'Delete user',
        info: 'All maps, workflows, connections and credentials from {userName} must be transferred to another user. M2M OAuth Clients owned by this user will be deleted for security reasons, and any projects using them will stop working. We recommend replacing them before deleting or downgrading this user',
        newAssetOwnerLabel: 'New asset owner:',
        newAssetOwnerPlaceholder: 'Select user',
        newAssetOwnerInfo: 'Assets can only be transferred to users with Editor, Admin or Superadmin roles',
        errorMessage: 'CARTO could not delete the user. Please try again, or contact us if the problem persists.',
        delete: 'Yes, delete',
        successMessage: 'User deleted successfully'
      },
      makeViewerDialog: {
        title: 'Assign viewer role',
        content: 'Are you sure you want to assign {email} a viewer role?',
        info: 'All maps, workflows, connections and credentials from {userName} must be transferred to another user. M2M OAuth Clients owned by this user will be deleted for security reasons, and any projects using them will stop working. We recommend replacing them before deleting or downgrading this user',
        cancel: 'Cancel',
        confirm: 'Yes, assign viewer role',
        errorMessage: 'CARTO could not change the user role. Please try again, or contact us if the problem persists.',
        successMessage: 'Role changed successfully',
        newAssetOwnerLabel: 'New asset owner:',
        newAssetOwnerPlaceholder: 'Select user',
        newAssetOwnerInfo: 'Assets can only be transferred to users with Editor, Admin or Superadmin roles'
      },
      searchPlaceholder: 'Search users',
      notFound: 'User not found',
      defaultUserRole: {
        title: 'Default role for new users',
        titleWithSsoGroups: 'Default role for new users without groups',
        propertyCaption: 'Current default role',
        dialogMessage: 'Define which role will be assigned by default to new users joining your organization.',
        dialogMessageWithSsoGroups:
          "Define which role will be assigned by default to new users joining your organization that don't belong to any group.",
        success: 'Default user role successfully changed.'
      },
      ssoGroups: {
        definedRole: 'Role defined by its SSO group'
      }
    },
    groups: {
      title: 'Groups',
      searchPlaceholder: 'Search groups',
      notFound: 'No groups matching you search criteria',
      empty: 'No groups have been synchronized yet',
      info: '<strong>Your groups are automatically synchronized</strong> using your Company Single Sign-On, also known as SSO. Every time an user in your organization logs in, we retrieve the group info from your company directory. <link>Read more in the documentation</link>.',
      actions: {
        rename: 'Rename group alias',
        showMembers: 'Show members',
        delete: 'Delete'
      },
      createDialog: {
        createGroup: 'Create group',
        title: 'Create a new group',
        description: 'Pre-create groups in CARTO before they are synchronized from your Single Sign-On (SSO){details}.',
        roleManagement: ', so that new users coming from that group get its assigned role',
        fields: {
          customName: 'Group alias',
          externalName: 'SSO group ID',
          externalNameInfo: 'This must be the ID of the desired group in your Identity Provider (IdP)',
          roleMapping: 'Group role mapping'
        },
        errors: {
          customName: 'This group alias is already being used, please write a new one.',
          externalName: 'This SSO group ID is already being used, please write a new one.'
        }
      },
      deleteDialog: {
        title: 'Delete group',
        content1:
          "You're about to delete the {groupName} group. Any assets shared with this group will no longer be accessible to its members.",
        content2:
          'If this group is not removed from your Identity Provider (IdP), it will be recreated the next time a group member logs in. <a>Learn more</a>',
        confirm: 'Yes, delete it',
        successNotificationMessage: 'Group successfully deleted.',
        errorNotificationMessage: 'Error deleting the group'
      },
      selectAnAdminGroupDialog: {
        title: 'Select an admin group',
        description:
          'Before mapping your groups to roles, you need to select one admin group (you can map more in the future) <strong>to avoid losing admin access to the organization</strong>. Our recommendation is that you choose a group that you, as an admin, belong to.',
        info: 'After selecting an admin group, you can continue the group-role mapping in the "Groups" tab.',
        group: 'Group',
        selectGroup: 'Select a group'
      },
      roleChangeDialog: {
        title: 'Group role mapping',
        confirm: 'Yes, assign {role} role',
        content: 'Are you sure you want to assign the {role} role to the group {groupName}?',
        info: 'The role for each user will be updated the next time they log in.',
        infoViewer:
          'All assets (such as maps, connections and apps) owned by the users in this group will be transferred to you.',
        defaultRoleLabel: 'Select group role'
      },
      renameDialog: {
        rename: 'Rename',
        genericErrorMessage: 'Something went wrong',
        duplicatedErrorMessage: 'This group alias is already in used'
      },
      table: {
        headCell: {
          alias: 'Group alias',
          name: 'SSO group name',
          ssoId: 'SSO group ID',
          members: 'Members',
          groupRole: 'Group role'
        }
      }
    },
    groupMembers: {
      dialog: {
        title: '{group_name} members'
      },
      searchPlaceholder: 'Search group members',
      notFound: 'No users where found',
      tryAgain: 'Try another search',
      empty: 'No groups members have been synchronized yet',
      table: {
        headCell: {
          email: 'User',
          role: 'Role'
        }
      }
    },
    apps: {
      title: 'Applications',
      description:
        'As an Admin, you can add any application (developed with or without CARTO) to the Workspace, so that all or some of your users have an easy access to it. These applications will be shown in the Applications page.',
      table: {
        headCell: {
          name: 'Name',
          added: 'Added'
        }
      },
      registerNew: 'Add new',
      registerNewShort: 'New',
      notFound: 'No registered apps found',
      createdApp: {
        savedSuccess: 'Changes saved successfully'
      },
      editedApp: {
        savedSuccess: 'App edited successfully'
      },
      registerAppDialog: {
        title: 'Registered app',
        edit: 'Edit registered app',
        create: 'Create',
        save: 'Save',
        savedSuccess: 'Registered app saved successfully'
      }
    },
    branding: {
      info: {
        lookingEnable: {
          title: 'Looking to customize CARTO?',
          message: "Let us know and we'll help you get started with your custom appearance."
        },
        upgrade: {
          title: 'Upgrade your plan',
          message:
            "Customizing the appearance in CARTO requires a larger Enterprise plan. Let us know if you're interested and we'll guide you through the process."
        }
      }
    },
    integrations: {
      title: 'Integrations'
    },
    analytics: {
      analyticsToolbox: 'Analytics Toolbox',
      title: 'Installations',
      newInstallation: 'New installation',
      none: "You don't have an Analytics Toolbox installation yet",
      help: 'Learn how to install the CARTO Analytics Toolbox',
      table: {
        header: {
          dataWarehouse: 'Data warehouse',
          location: 'Location',
          installationDate: 'Installation Date',
          version: 'Version',
          status: 'Status'
        },
        actions: {
          install: 'Install',
          changeCredentials: 'Change credentials',
          uninstall: 'Uninstall'
        }
      },
      selectDataWarehouse: {
        subTitle: 'Select a data warehouse',
        helpDefault: 'Select a data warehouse to install the Analytics Toolbox',
        snowflake: {
          p1: 'The {provider} Analytics Toolbox provides geospatial functions to perform fundamental spatial operations, such as clustering and data enrichment, natively within the {provider} environment.',
          p2: '<a>See documentation</a>.'
        },
        manual: {
          p1: 'Currently we only have support for a <strong>manual installation process</strong>.',
          p1BigQuery:
            'Access to the Analytics Toolbox via your connections to BigQuery is automatic except for VPC environments, for which we only have support for a <strong>manual installation process</strong>.',
          p2: 'In order to receive assistance and all necessary instructions to complete the manual installation please contact CARTO at <a>support@carto.com</a>.',
          p3: 'For more details about the Analytics Toolbox for {provider}, please access <a>our product documentation</a>.'
        }
      },
      connectWarehouseForm: {
        title: 'New installation',
        validateSetup: 'Validate setup',
        common: {
          requiredSetup: 'Required setup'
        },
        snowflake: {
          info: {
            p1: 'CARTO requires a specific setup in your Snowflake account to be able to securely install the Analytics Toolbox and then access it from our different  platform interfaces. To ensure the best experience we request that you create a database and a schema called  ‘carto’ , plus a user and a role, to connect in a secure way to perform the installation and future updates.',
            p2: 'To ensure the best experience, please follow the <a>detailed instructions</a> to securely setup your Snowflake account for the Analytics Toolbox installation.'
          },
          form: {
            requiredSetupConfirmation: 'I have already completed the setup of my Snowflake account',
            title: 'Connect to your Snowflake account',
            info: 'Provide the following details in order to establish a connection with the ‘carto’ user to the ‘carto’ database and ‘carto’ schema that you have recently setup in order to continue with the Analytics Toolbox installation. CARTO will also use this connection to update the Analytics Toolbox when new versions become available.',
            username: 'Username',
            database: 'Database',
            password: 'Password',
            role: 'Role',
            account: 'Account Name'
          }
        }
      },
      atInstallationDialog: {
        validation: {
          title: 'Setup validation',
          info: 'Validating that we have access to all the necessary resources before proceeding with the installation.',
          inProgressStatus: 'Access to Snowflake account…'
        },
        installation: {
          title: 'Installation',
          info: ' ',
          inProgressStatus: 'Installing Analytics Toolbox'
        },
        completed: 'Completed',
        install: 'Install'
      },
      atUninstallationDialog: {
        title: 'Uninstall',
        confirmQuestion: 'Do you want to uninstall this Analytics Toolbox?',
        uninstall: 'Yes, uninstall',
        inProgressStatus: 'Uninstalling Analytics Toolbox',
        completed: 'Completed',
        error: 'Error',
        failedAlert: 'There was a problem during the uninstallation process, please try again.'
      },
      atUpdateDialog: {
        title: 'Analytics Toolbox Update',
        message: {
          p1: 'Version {version} is available for {providerLabel}. Would you like to install it now??',
          p2: 'Check the <a>release notes</a> for more details'
        },
        update: 'Yes, update',
        inProgressStatus: 'Updating Analytics Toolbox',
        completed: 'Completed',
        error: 'Error',
        failedAlert: 'There was a problem during the installation process, please try again.'
      },
      changeConfigForm: {
        title: 'Change credentials',
        validateAndSave: 'Validate and save',
        prompt: 'Your Snowflake credentials',
        successNotification: 'Credentials has been successfully changed.'
      },
      atUpdateConfigDialog: {
        title: 'Credentials validation',
        prompt: 'Validating that we have access to all the necessary resources.',
        updateConfig: 'Yes, update config',
        inProgressTitle: 'Validation',
        inProgressStatus: 'Access to snowflake account',
        completed: 'Completed',
        error: 'Error',
        failedAlert: 'There was a problem saving new config process, please try again.'
      },
      states: {
        available: 'Available',
        updateAvailable: 'Update available'
      }
    },
    assetManagement: {
      default: {
        title: 'Untitled'
      },
      maps: {
        title: 'Maps',
        searchPlaceholder: 'Search map',
        noResults: 'No maps were found',
        emptyState: "Your organization doesn't have any maps yet.",
        tableHead: {
          name: 'Name',
          owner: 'Owner',
          lastUpdated: 'Last updated',
          sharedWith: 'Shared with'
        }
      },
      workflows: {
        title: 'Workflows',
        searchPlaceholder: 'Search workflow',
        noResults: 'No workflows were found',
        emptyState: "Your organization doesn't have any workflows yet."
      },
      connections: {
        title: 'Connections',
        searchPlaceholder: 'Search connection',
        noResults: 'No connections were found',
        emptyState: "Your organization doesn't have any connections yet."
      },
      tableHead: {
        name: 'Name',
        owner: 'Owner',
        lastUpdated: 'Last updated',
        sharedWith: 'Shared with',
        maps: 'Maps'
      },
      actions: {
        delete: 'Delete',
        transfer: 'Transfer',
        assetsSelected: '{count} assets selected',
        transferDialog: {
          header: 'Transfer {assetTypes}',
          contentOne: 'The {assetTypes} {assetName} will be transferred',
          contentGroup: '{count} {assetTypes}s will be transferred',
          autocompleteFromLabel: 'From:',
          autocompleteToLabel: 'To:',
          autocompleteToPlaceholder: 'Select user',
          infoCaption: 'Assets can only be transferred to users with Editor, Admin or Superadmin roles',
          warning:
            'Transferred assets may have errors if the new owner does not have access to the connections/sources used.',
          warningConnection:
            "Transferring a connection may lead to errors in maps, workflows, or apps that use the transferred connection. <br></br><br></br>By sharing this connection you're also sharing the permissions it might have.",
          transferButton: 'Transfer',
          successTransferMessage: '{resourceType} transferred successfully',
          partialErrorMessage:
            'CARTO could not transfer {failed} of {totalCount} selected assets. Please try again, or contact us if the problem persists.',
          errorMessage:
            'CARTO could not transfer the selected asset. Please try again, or contact us if the problem persists.'
        },
        deleteDialog: {
          header: 'Delete {assetTypes}',
          contentOne: 'Are you sure you want to delete {assetName}? This action cannot be undone.',
          contentGroup: 'Are you sure you want to delete {count} selected {assetTypes}s? This action cannot be undone.',
          cancelButton: 'Cancel',
          confirmDeleteButton: 'Yes, delete',
          warningConnectionTitle: 'Deleting these connections will also delete:',
          warningConnectionBody: '{mapCount} maps and {workflowCount} workflows',
          successDeleteMessage: '{resourceType} deleted successfully',
          partialErrorMessage:
            'CARTO could not delete {failed} of {totalCount} selected assets. Please try again, or contact us if the problem persists.',
          errorMessage:
            'CARTO could not delete the selected asset. Please try again, or contact us if the problem persists.'
        }
      }
    },
    dataObservatory: {
      subscriptions: 'Subscriptions',
      table: {
        name: 'Name',
        category: 'Category',
        version: 'Version',
        license: 'License',
        expirationDate: 'Expiration date',
        status: 'Status'
      },
      statusLabels: {
        subscribed: 'Subscribed',
        expired: 'Expired',
        expiresSoon: 'Expires soon'
      },
      noExpirationDate: 'None',
      emptyState: {
        title: 'You currently do not have any data subscription in your organization',
        subtitle: 'Explore the catalog of spatial data  in our Data Observatory',
        button: 'Go to Data Observatory'
      },
      removeSubscriptionDialog: {
        title: 'Remove subscription from the list',
        content:
          'Are you sure you want to remove this item from the list? This subscription will no longer be visible in this page.',
        cancelButton: 'Cancel',
        confirmButton: 'Yes, remove',
        subscriptionRemovedSuccessfully: 'Subscription removed successfully'
      }
    }
  },
  supportAccess: {
    title: 'Enabling Support access',
    description:
      'Allow CARTO temporary access to your organization so that our Support and Customer Success teams can onboard you, assist with new functionality, or troubleshoot issues. You can revoke this access at any time.',
    enableButton: 'Enable access',
    disabledMessage:
      'Support access is disabled because your organization is enforcing Single Sign-On. <a>More info</a>',
    accessAllowed: 'Access allowed',
    accessAllowedDescription: 'Support access granted on {date} by {user}. Due to expire on {expirationDate}.',
    revokeButton: 'Revoke access',
    revokeDialog: {
      title: 'Revoke Support access',
      description:
        'Our team will no longer have access to your organization. You can grant us permission again at any time.',
      alert: 'All maps, workflows, connections and credentials from {user} must be transferred to another user',
      form: {
        assetOwner: 'New asset owner:',
        assetOwnerDescription: 'Assets can only be transferred to users with Editor, Admin or Superadmin roles',
        button: 'Revoke access'
      },
      success: 'Support access revoked.'
    },
    enableDialog: {
      title: 'Enable Support access',
      description:
        'When enabling Support access, you authorize specific support personnel within CARTO to have administrator-level role access to your Workspace. You can remove this access at any time.',
      form: {
        duration: 'Duration',
        durationDays: '{count, plural, one {# day} other {# days}}',
        authorize: 'Authorize access',
        authorizeInfo:
          'I understand that by enabling access, I authorize the CARTO team to access my organization for the specified time period.',
        button: 'Enable access'
      },
      success: 'Support access enabled.'
    },
    userEmail: 'CARTO Support',
    userActionsInfo: 'You can manage CARTO Support access from the General section of the organization settings'
  },
  delete: {
    title: 'Delete organization',
    warning:
      'Delete the organization, including all the maps, connections, users (including yourself) and any content created. Proceed with caution.',
    button: 'Delete organization',
    dialog: {
      title: 'Delete organization',
      description: {
        line1: "Are you sure you want to delete the whole organization? This action can't be undone",
        line2: 'Enter the name of the organization to confirm the action'
      },
      accountNameInput: {
        label: 'Organization name'
      },
      deleteButton: 'Yes, delete organization'
    }
  },
  lds: {
    quotas: {
      dialog: {
        cancel: 'Cancel'
      },
      geocoding: {
        title: "Couldn't process geocoding",
        subtitle:
          "You're running out of location data services quota. Check if the table you requested is too large for the remaining quota available.",
        barLabel: 'Geocoding and Isolines',
        warning: 'Remember that your quota will be reset on {date}. Request to be contacted for an upgrade.'
      }
    }
  },
  roles: {
    admin: 'Admin',
    editor: 'Editor',
    builder: 'Editor',
    viewer: 'Viewer',
    superadmin: 'Superadmin'
  },
  branding: {
    title: 'Appearance',
    description:
      'Use your own colors and logo to whitelabel the CARTO interface and provide a custom branded experience for all users.',
    customizeMobile: 'Customize',
    customize: 'Customize appearance',
    logo: 'Logo',
    changeLogo: 'Change logo',
    logoHelper: 'Logo must be square, min size 256x256 and max size 1MB',
    colors: 'Colors',
    primaryColor: 'Primary color',
    primaryColorTooltip:
      'Used to represent primary interface elements such as buttons, menu backgrounds, checkbox colors...',
    secondaryColor: 'Secondary color',
    secondaryColorTooltip: 'Used for accent in some contexts like charts or data workflow',
    appBarBackgroundColor: 'App Bar background color',
    appBarBackgroundColorTooltip: 'Used for accent in some contexts like charts or data workflows',
    appBarContrastTextColor: 'App Bar contrast text',
    appBarContrastTextColorTooltip: 'The text color for the items and title in the top bar',
    usePrimaryColorsForAppBarLabel: 'Use primary colors for App Bar (header)',
    updateError: 'An error occurred while branding update, please try again.',
    logoUpdateError: 'An error occurred while logo upload, please try again.',
    publicMaps: {
      title: 'Public and embedded maps',
      description:
        'Customize your public and embedded maps by showing or removing the CARTO footer (including the logo and social media icons)',
      control: 'Show CARTO footer'
    },
    onboarding: {
      title: 'Onboarding',
      description:
        'Customize whether new users in this organization will see the regular onboarding experience when they first join CARTO',
      control: 'Show onboarding to new users'
    },
    getInTouch: 'Get in touch with the CARTO team to get access to this functionality',
    customCodeInBuilder: {
      title: 'Custom code for maps',
      description:
        'Add custom code to all your organization’s Builder maps. This code will be applied whether accessing a map shared with the organization/groups or a publicly available map.',
      warning: {
        title: "CARTO doesn't validate custom code",
        description:
          "Custom code is an advanced modification that may conflict with CARTO's functionality. Additionally, our support team is unable to provide direct assistance with custom code setup or troubleshooting."
      },
      header: {
        title: 'Custom HTML',
        saveHtml: 'Save HTML',
        successMessage: 'HTML code saved successfully'
      }
    }
  },
  basemaps: {
    title: 'Custom basemaps',
    empty: "You haven't added any basemap yet",
    new: 'New basemap',
    limitReached: 'You have reached the limit of {limit} custom basemaps.',
    switches: {
      title: 'Enabled basemaps in Builder',
      carto: 'CARTO basemaps',
      google: 'Google Maps basemaps',
      custom: 'Custom basemaps'
    },
    tooltips: {
      atLeastOneCategory: 'At least one basemap category must remain active',
      atLeastOneCustomBasemap: 'At least one basemap must exist if custom category is the only one enabled',
      addBeforeEnable: 'Add a custom basemap to enable this option',
      noGoogleApiKey: 'There is no Google Maps API key set for the tenant'
    },
    deleteDialog: {
      title: 'Delete custom basemap',
      success: 'Basemap deleted',
      description: {
        1: 'Are you sure you want to delete this basemap? This action cannot be undone.',
        2: '<strong>basemapName</strong> basemap will no longer be accessible to Editors in CARTO Builder.',
        3: "Existing maps using this basemap will remain unaffected, but Editors won't be able to use it when creating or editing maps."
      }
    },
    dialog: {
      title: 'New custom basemap',
      previewNotAvailable: 'Preview not available',
      form: {
        url: 'URL',
        urlHelper: 'Find out more about the supported URL formats in our <a>documentation</a>',
        urlError: 'Invalid URL. Check and submit a valid URL for basemap addition.',
        name: 'Name',
        urlPlaceholder: 'Paste or type the URL',
        namePlaceholder: 'Type the name',
        attribution: 'Attribution',
        attributionPlaceholder: 'Type the copyright attribution'
      },
      add: 'Add basemap'
    },
    detailsDialog: {
      delete: 'Delete basemap'
    }
  },
  workflows: {
    extensionPackages: {
      title: 'Extension Packages',
      description:
        'Extend Workflows functionality with the installation of Extension Packages including custom components for more specialized analytics.',
      enabled: 'Allow the installation of Extension Packages'
    }
  },
  palettes: {
    title: 'Organization color palettes',
    subtitle:
      'These custom color palettes will be available to all editors in your organization when styling layers in CARTO Builder maps.',
    new: 'New palette',
    limitReached: 'You have reached the limit of {limit} organization color palettes.',
    actions: {
      edit: 'Edit',
      duplicatePalette: 'Duplicate palette',
      delete: 'Delete'
    },
    deleteSuccess: 'Palette deleted',
    newPaletteDialog: {
      title: 'New organization palette',
      name: 'Name',
      helperTextAlreadyExists: 'There is already a palette with this name.',
      namePlaceholder: 'Name of the new palette',
      type: 'Type',
      typePlaceholder: 'Select a palette type',
      typeOptions: {
        diverging: 'Diverging',
        sequential: 'Sequential',
        qualitative: 'Qualitative',
        singlehue: 'Singlehue',
        custom: 'Custom'
      },
      addColor: 'Add color',
      addColorIndicator: 'Palettes must have at least 3 colors.',
      createPalette: 'Create palette',
      deletePalette: 'Delete palette',
      confirmDelete: 'Yes, delete',
      done: 'Done',
      keepEditing: 'Keep editing',
      warningConfirmationContent: 'If you leave, you will lose your current colour palette.',
      warningConfirmDeleteTitle: 'Delete organization palette',
      warningConfirmDeleteContent1: 'Are you sure you want to delete this palette? This action cannot be undone.',
      warningConfirmDeleteContent2: '{palette_name} palette will no longer be accessible to Editors in CARTO Builder.',
      warningConfirmDeleteContent3:
        "Existing maps using this palette will remain unaffected, but Editors won't be able to use it when creating or editing maps.",
      limitReached: 'You have reached the limit of {limit} colors for a palette.'
    },
    deletePaletteDialog: {
      title: 'Delete organization palette',
      description1: 'Are you sure you want to delete this palette? This action cannot be undone.',
      description2: '{palette_name} palette will no longer be accessible to Editors in CARTO Builder.',
      description3:
        "Existing maps using this palette will remain unaffected, but Editors won't be able to use it when creating or editing maps.",
      cancel: 'Cancel',
      confirm: 'Yes, delete'
    }
  },
  aiAgents: {
    title: 'CARTO AI in Builder',
    description:
      'Transform your Builder maps with CARTO AI Agents for a more engaging experience.\n\nEditors can enable these Agents, allowing Viewers to have a conversation with the map, making data exploration intuitive and interactive.',
    link: 'Learn more about AI Agents in our Documentation',
    control: 'Enable CARTO AI in Builder',
    controlHelper: 'By enabling CARTO AI, you agree to the {actionLink}',
    conditions: 'CARTO AI terms and conditions.'
  }
}
