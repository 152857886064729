/**
 * Using Modal provoke a warning message in browser console.
 * This is an error caused by Material UI and React.StrictMode.
 * It will be fixed in v5:
 * => https://stackoverflow.com/a/64135466/3631988
 */
import { useState, useEffect } from 'react'
import { Modal, styled } from '@mui/material'
import { IconButton } from '@carto/meridian-ds/components'
import { Close } from '@mui/icons-material'

const Root = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '90%',
  margin: '0 auto',
  '& > *': {
    outline: 'none'
  }
}))

const Content = styled('div')(() => ({
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  position: 'relative'
}))

const Children = styled('div')(() => ({
  maxWidth: '100%'
}))

interface ModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
  childrenClass?: string
  open: boolean
  onCloseModal?: { (): void }
  closeOnBackdrop?: boolean
  displayCloseIcon?: boolean
  hideBackdrop?: boolean
}

function ModalWrapper(props: ModalProps) {
  const {
    onCloseModal,
    childrenClass,
    closeOnBackdrop = true,
    displayCloseIcon = true,
    open = false,
    hideBackdrop
  } = props
  const [isOpen, setIsOpen] = useState(open)
  const closeModal = () => {
    setIsOpen(false)
    if (onCloseModal) {
      onCloseModal()
    }
  }

  const handleClose = (_, reason) => {
    if (reason === 'backdropClick') {
      closeOnBackdrop && closeModal()
    }
  }

  useEffect(() => {
    setIsOpen(open)
  }, [open, setIsOpen])

  return (
    <Root open={isOpen} onClose={handleClose} hideBackdrop={hideBackdrop}>
      <Content>
        {displayCloseIcon && <IconButton onClick={closeModal} sx={{ mb: 1 }} icon={<Close />} />}
        <Children className={childrenClass || ''}>{props?.children}</Children>
      </Content>
    </Root>
  )
}

export default ModalWrapper
