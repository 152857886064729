export default {
  documentationLink: 'Check the documentation to get started',
  welcome: {
    title: 'Welcome to CARTO for Snowflake',
    description: 'Allow CARTO to access your Snowflake data to get started',
    button: 'Setup my Snowflake connection'
  },
  secondAttempt: {
    title: 'Granting CARTO access to Snowflake...',
    description:
      'The process still needs to finish in a different window. If you are unable to continue, please restart the process.',
    button: 'Restart'
  }
}
