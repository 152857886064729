import dedent from 'ts-dedent'

export default {
  title: 'Maps',
  titleButton: 'New map',
  titleButtonShort: 'New',
  errorCurrentMapLocked: 'The map is currently being edited by another user. Try again later',
  emptyState: {
    caption: `You haven't created any maps yet`,
    button: 'Create your first map',
    canNotCreate: `No maps have been created yet`
  },
  filters: {
    search: 'Search map',
    title: '{elements} results by "{query}"',
    noResults: 'No maps were found',
    order: {
      updatedAt: 'Last updated'
    },
    privacy: {
      all: 'All',
      myMaps: 'My maps',
      shared: 'Organization',
      public: 'Public'
    },
    total: `{itemCount} {itemCount, plural,
        one {map}
        other {maps}
    }`
  },
  edit: {
    title: 'Edit map properties',
    inputs: {
      name: 'Name',
      description: 'Description',
      descriptionDefaultText: dedent(`
        ### "Your Title Here" 🌍

        ![Image: Replace with your own](https://insert-image-url-here.com)

        Welcome to your map's description panel! Below is a sample template to guide you, but feel free to express in your style. Markdown formatting is supported.

        ---

        #### About This Map:
        _Describe the main theme of your map here._

        #### Data Insights:
        _Share key findings or patterns visible in your map._

        ---
        #### Sample Markdown Tips:
        - \`##\` for headings.
        - \`**Bold**\` for **Bold**.
        - \`_Italicize_\` for _Italicize_.
        - \`[Link Text](#)\` to add links.
      `)
    },
    helpers: {
      description: 'Remaining characters: {remainingCharacters}'
    },
    buttons: {
      save: 'Save changes'
    }
  },
  cards: {
    tooltips: {
      publicMap: 'Public map',
      sharedMap: 'Shared with Organization',
      privateMap: 'Private map'
    },
    actions: {
      edit: 'Edit map properties',
      share: 'Share',
      delete: 'Delete',
      editThisDemo: 'Edit this demo'
    },
    deleteDialog: {
      title: 'Delete map',
      publicDescription:
        'Are you sure you want to delete {name}? This action cannot be undone and the public version of the map will become unavailable.',
      sharedDescription:
        'Are you sure you want to delete {name} from your organization? This action cannot be undone and other users will lose access to it.',
      privateDescription: 'Are you sure you want to delete {name}? This action cannot be undone.',
      errorMessage: 'Could not delete map, please try again',
      delete: 'Yes, delete'
    },
    demo: {
      tag: 'Demo'
    }
  },
  sample: {
    overline: 'Need ideas?',
    title: 'How to get started'
  },
  tutorials: {
    overline: 'Learn how',
    title: 'Related tutorials'
  },
  default: {
    title: 'Untitled'
  },
  creatingDemoMap: 'Creating demo map...',
  quota: {
    limitHit:
      'You have used all the maps ({limit}) available in your current plan. Request an upgrade to continue creating maps',
    wouldExceedQuotaModal: {
      create: {
        title: "Couldn't create new map",
        message: 'Check the exceeded quotas below before creating a new map.'
      },
      duplicate: {
        title: "Couldn't duplicate map",
        message: 'Check the exceeded quotas below before duplicating a map.'
      },
      share: {
        title: "Can't share more maps",
        message: 'Check the exceeded quotas below before sharing more maps.',
        alertMessage:
          'You can’t share any more maps with your current plan. Free a map by modifying or removing other maps, or request to be contacted for an upgrade.'
      },
      moreInfo: {
        title: "Can't create new maps"
      },

      quotaTitle: 'Maps',
      alertMessage:
        'You can’t create any more maps with your current plan. Free up space by deleting other maps or request to be contacted for an upgrade.'
    },

    shareMapsQuotaHitWarning:
      'You have used all the public maps ({limit}) available in your current plan. Request an upgrade to continue publishing maps.'
  }
}
